<template>
    <div class="card bs-5 mb-5">

        <div class="fl-te-c pb-3">
            <h4>ABOUT US</h4>
            <div>
                <btn color="success" icon="fa fa-plus" size="md" @click="$refs.aboutUsAddModal.show()" text="Add"/>
            </div>
        </div>

        <custom-vue-table :show-search-box="false" :fields="fields" :url="listUrl"
                          class="c-cms-table d-sm-none-lg-block" :per-page="10" ref="table">

            <template #banner_image="{rowData}">
                <div class="container-image pos-r fl-x-cc fl-a-c" @click="viewFile(rowData.banner_image)">
                    <img :src="rowData.banner_image" alt="Avatar" class="c-image cursor-pointer">
                    <div class="c-overlay">
                        <div class="c-icon ml-3">
                            <i class="fa fa-eye"></i>
                        </div>
                    </div>
                </div>
            </template>

            <template #is_enabled="{rowData}">
                <p v-if="rowData.is_enabled">Yes</p>
                <p v-else>No</p>
            </template>

            <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <edit-btn icon="fa fa-eye" text="View" @click="viewDetails(rowData.id)"/>
                    <delete-btn design="basic" @click="setDelete(rowData)"/>
                </div>
            </template>

        </custom-vue-table>
        <about-us-mobile-view @viewDetails="viewDetails" @setDelete="setDelete"
                              class="d-lg-none" ref="aboutMobileView"></about-us-mobile-view>

        <modal title="Add About Us" ref="aboutUsAddModal" width="60r" :no-close-on-backdrop="true"
               header-color="primary">
            <AddAboutUs @success="formSuccess"></AddAboutUs>
        </modal>

        <delete-modal ref="aboutUsDeleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
            <p>You are about to delete the About Us. Are you sure ?
            </p>

            <template #loading>
                <loading-animation/>
                Please wait while we delete the item.
            </template>
        </delete-modal>
    </div>
</template>

<script>
import urls from '@/data/cms-urls';
import AddAboutUs from './AddAboutUs';
import AboutUsMobileView from '@/views/cms/about-us/about-us/AboutUsMobileView';

export default {
    name : 'AboutUs',

    components : { AboutUsMobileView, AddAboutUs },

    data () {
        return {
            listUrl   : urls.cms.aboutUs.list,
            deleteUrl : urls.cms.aboutUs.delete,

            deletingItem : null,

            fields : [
                {
                    name      : 'id',
                    sortField : 'id',
                    title     : 'ID'
                },
                {
                    name  : '__slot:banner_image',
                    title : 'Banner Image'
                },
                {
                    name      : 'created_at',
                    sortField : 'created_at',
                    title     : 'Created At'
                },
                {
                    name  : '__slot:is_enabled',
                    title : 'Enabled'
                },
                {
                    name       : '__slot:actions',
                    title      : 'Actions',
                    titleClass : 'center bg-gray aligned text-right',
                    dataClass  : 'center aligned text-right'
                }
            ]
        };
    },
    methods : {
        viewFile (file) {
            window.open(file);
        },
        viewDetails (id) {
            this.$router.push('/cms/about-us/' + id + '/details/');
        },
        formSuccess () {
            const refs = this.$refs;
            refs.aboutUsAddModal.close();
            refs.table.refreshTable();
            refs.aboutMobileView.loadData();
        },

        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.aboutUsDeleteModal.show();
        },

        deleteComplete (response) {
            if (response.error === true) {
                if (response.message) {
                    this.$notify(response.message, 'Failed', { type : 'danger' });
                } else {
                    this.$notify('Failed to delete, Please try again later', 'Failed',
                        { type : 'danger' }
                    );
                }
            } else {
                this.$notify('Successfully Deleted About Us..!', 'Success', { type : 'success' }
                );
            }
            this.deletingItem = null;
            this.$refs.aboutUsDeleteModal.close();
            this.$refs.table.refreshTable();
            this.$refs.aboutMobileView.loadData();
        }

    }
};
</script>

<style scoped>

</style>
